import theme from 'gatsby-theme-listing/src/gatsby-plugin-theme-ui'

export default {
    ...theme,
    fonts: {
        body: "'Josefin Sans', sans-serif",
        heading: "'Cardo', serif",
        monospace: "'Cardo', serif",
      },
    fontWeights: {
        body: 300,
        heading: 700,
        bold: 700,
    },  
    colors: {
        text: '#141414',
        background: '#fff',
        grey: '#636363',
        primary: '#fc4d60',
        secondary: '#fdac2e',
        muted: '#f6f7f8',
    },
}